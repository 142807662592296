import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/abel"; // Agency FB
import "@fontsource/aclonica"; // Agency FB
import "@fontsource/sarpanch"; // Agency FB

import Unordered from "~/components/List/Unordered";

const Top = (): JSX.Element => {
  return (
    <div className="bg-cbd-now-yellow py-8 relative border-red-800 border-4">
      <span className="bg-gradient-to-r from-red-400 to-red-800 w-32 h-full z-0 -skew-x-12 transform absolute top-0 left-24" />
      <span className="hidden md:block  bg-gradient-to-r from-red-400 to-red-800 w-32 h-full z-0 -skew-x-12 transform absolute top-0 left-96" />
      <span className="hidden lg:block bg-gradient-to-r from-red-400 to-red-800 w-32 h-full -skew-x-12 transform absolute top-0 right-12" />
      <span className="relative flex text-center md:text-left justify-center text-4xl text-indigo-800 font-semibold w-full z-30" style={{ fontFamily: "Sarpanch" }}>Energy Drinks - Best Prices!</span>
      <span className="absolute text-xs text-black top-0 right-0 z-30">Advertisement</span>
    </div>
  );
};

export const CBDNow = (): JSX.Element => (
  <h1 className="text-4xl text-black font-bold">
    <span className="flex space-x-3 items-end underline" style={{ fontFamily: "Aclonica" }}>
      CBD Now
      <StaticImage
        alt="Tear drop."
        className="ml-2"
        src="../../../../images/cbd-now-tear.png"
        loading="eager"
        objectFit="cover"
        width={40}
      />
    </span>
    <span className="font-normal z-20">
      Quality CBD Products
    </span>
  </h1>
);

export const Navigation = (): JSX.Element => {
  return (
    <div className="relative">
      <StaticImage
        alt="Marijuana leaves."
        className="bg-white opacity-30 overflow-hidden inset-0 z-0"
        imgClassName="z-0"
        src="../../../../images/cbd-now-bg-banner.jpg"
        loading="eager"
        objectFit="cover"
        style={{ position: "absolute" }}
      />
      <div className="flex flex-col flex-wrap space-y-3 mx-3 md:space-x-3 md:flex-row md:flex-nowrap md:justify-center md:items-center lg:space-x-12 py-8 h-full z-10">
        <Link to="/article/cbd/cbd-now" className="md:hidden">
          <CBDNow />
        </Link>
        <Link to="/article/cbd/cbd-now/" className="z-20">HOME</Link>
        <Link to="/article/cbd/cbd-now/products" className="z-20">PRODUCTS</Link>
        <Link to="/article/cbd/cbd-now" className="hidden md:block">
          <CBDNow />
        </Link>
        <Link to="/article/cbd/cbd-now/our-mission" className="z-20">MISSION</Link>
        <Link to="/article/cbd/cbd-now/about-us" className="z-20">ABOUT US</Link>
        <Link to="/article/cbd/cbd-now/contact" className="z-20">CONTACT</Link>
        <Link to="#" className="z-20"><i className="fas fa-shopping-cart" /></Link>
      </div>
    </div>
  );
};

const Jumbotron = (): JSX.Element => {
  return (
    <div className="relative w-full overflow-hidden" style={{ maxHeight: "750px" }}>
      <div className="absolute top-0 right-0 p-8 max-w-lg text-black z-10">
        <h3 className="text-xl md:text-4xl">CBD Now is your source for quality CBD products</h3>
        <button className="mt-3 md:mt-10 px-3 py-2 text-xl rounded bg-black text-white outline-none focus:ring-4 focus:ring-gray-600">
          <Link to="/article/cbd/cbd-now/products">SHOP NOW</Link>
        </button>
      </div>
      <div className="absolute bottom-0 right-0 md:p-8 text-black text-4xl z-10">
        <p>www.cbdnow.com</p>
      </div>
      <StaticImage
        alt="CBD oil in dropper."
        src="../../../../images/cbd-now-jumbotron.jpg"
        loading="eager"
        objectFit="cover"
      />
    </div>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 flex-grow">
      <div className="flex flex-wrap w-full justify-center">
        <div className="bg-gray-300 max-w-sm rounded-lg p-5">
          <p>Cannabidiol (CBD) is a compound found in the flower of the cannabis plant. Unlike THC, the chemical in marijuana that makes a person feel high or intoxicated, CBD does not affect a person's thinking or behavior.</p>
        </div>
        <div className="flex justify-center w-full mt-5">
          <p className="max-w-4xl">Scientific research and accounts from patients who have used it support the use of CBD as a potentially valuable treatment. CBD has been investigated for treating many health problems, including</p>
        </div>
        <div className="flex justify-center w-full mt-5">
          <Unordered>
            <li>Arthritis</li>
            <li>Inflammation</li>
            <li>Epilepsy</li>
            <li>Seizures</li>
            <li>Alzheimer's disease</li>
            <li>Parkinson's disease</li>
            <li>Multiple sclerosis</li>
            <li>Huntington's chorea</li>
            <li>Stroke</li>
            <li>Traumatic brain injury</li>
            <li>Diabetes</li>
            <li>Obesity</li>
            <li>Depression</li>
          </Unordered>
          <Unordered>
            <li>Autism</li>
            <li>Anxiety</li>
            <li>ADHD</li>
            <li>PTSD</li>
            <li>Alcoholism</li>
            <li>Colitis</li>
            <li>Crohn's disease</li>
            <li>Atherosclerosis</li>
            <li>Arrhythmia</li>
            <li>Acne</li>
            <li>Dermatitis</li>
            <li>Psoriasis</li>
            <li>Insomnia</li>
          </Unordered>
        </div>
        <div className="flex justify-center w-full mt-5">
          <StaticImage
            alt="Map of USA with marijuana leaves as background."
            className="w-full md:w-1/2 lg:w-1/3"
            src="../../../../images/cbd-now-america.jpg"
            loading="lazy"
            objectFit="cover"
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap space-y-3 items-center md:space-x-5 mt-5">
          <div className="bg-gray-300 max-w-sm rounded-lg p-5 md:h-full">
            <p>In 2018, an almost pure pharmaceutical CBD formulation was approved as a treatment for two severe forms of epilepsy.</p>
          </div>
          <div className="bg-gray-300 max-w-sm rounded-lg p-5 md:h-full">
            <p>CBD oil is legal in 30 states where medicinal and/or recreational marijuana is legal, according to Governing magazine. Seventeen additional states have CBD-specific laws on the books, according to Prevention magazine.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const BottomJumbotron = (): JSX.Element => {
  return (
    <div className="relative w-full md:overflow-hidden">
      <div className="md:absolute max-w-xl text-gray-700 md:text-white p-8 pt-2 z-10">
        <h2 className="text-3xl">How Does CBD Work?</h2>
        <p className="mt-5">CBD and THC interact with our bodies in a variety of ways. One of the main ways is by mimicking chemicals naturally found in our bodies.</p>
        <p className="mt-5">These naturally occurring chemicals in our bodies help regulate a broad range of physiological processes, including mood, energy levels, immune activity, blood pressure, bone density, glucose metabolism, pain responses, stress, hunger, and more.</p>
        <p className="mt-5">What happens if these naturally occurring chemicals don’t function properly in our bodies? Using substances like CBD that mimic the naturally occurring chemicals can potentially slow or reverse health problems.</p>
        <p className="text-xs mt-8">Resource: Kubala, J. (2018). 7 Benefits and Uses of CBD Oil (Plus Side Effects).</p>
        <p>
          <a
            className="text-xs"
            href="https://www.healthline.com/nutrition/cbd-oil-benefits"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.healthline.com/nutrition/cbd-oil-benefits
          </a>
        </p>
      </div>
      <StaticImage
        alt="CBD oil in dropper."
        className="w-full md:w-auto"
        src="../../../../images/cbd-now-bottom-jumbotron.jpg"
        style={{ maxHeight: "838px" }}
        loading="lazy"
        objectFit="cover"
      />
    </div>
  );
};

const CBDNowPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Abel" }}>
      <Top />
      <Navigation />
      <Jumbotron />
      <Body />
      <BottomJumbotron />
      <Navigation />
    </div>
  );
};

export default CBDNowPage;
